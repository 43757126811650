* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  color: rgba(255, 255, 255, 0.8);
  background-color: #0a0a0a;
  font-family: "avenir", sans-serif;

  width: -webkit-fill-available;
  height: -webkit-fill-available;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

#app {
  display: flex;
  flex-direction: column;
  height: 900px;
  justify-content: space-between;
}

#top {
  width: 1300px;
  // height: 850px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .left,
  .right {
    display: flex;
    gap: 20px;
  }
}

#bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .left {
    .item {
      width: 170px;
    }
  }

  .left,
  .right {
    display: flex;
    gap: 20px;
  }
}

.pane {
  max-width: 230px;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 15px;
}

.action {
  cursor: pointer;
}

.poster {
  width: 230px;
  height: 130px;
  background-color: #131313;
  cursor: pointer;
}

.movies {
  .poster {
    margin-bottom: 5px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}

.body {
  max-height: 700px;
  overflow-y: scroll;
}

.title {
  margin: 16px 0;
}

.label {
  color: rgba(255, 255, 255, 0.4);
  margin-bottom: 3px;
}

.genres,
.cast {
  margin-bottom: 20px;
}

.thumbnail {
  margin-bottom: 14px;
}

li {
  list-style-position: inside;
}

.user-1 {
  color: var(--user-1) !important;
}

.user-2 {
  color: var(--user-2) !important;
}

.other-title {
  text-align: center;
  margin: 30px 0 15px;
}

.other-thumbnails {
  .poster {
    margin-bottom: 5px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}

.no-data {
  text-align: center;
  margin-top: 10px;
  opacity: 0.3;
}

:root {
  --user-1: #95e5a3;
  --user-2: #bf9cf6;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
*::-webkit-scrollbar-track {
  background: none;
}
*::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.1);
}
*::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.2);
}
*::-webkit-scrollbar-thumb:active {
  background: rgba(255, 255, 255, 0.2);
}
